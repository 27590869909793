.container {
  display: flex;
  flex-direction: column;
}

.search-filter-container {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.integrations-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 0 -8px;
  padding: 0 8px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  position: relative;
}

.loader-container {
  position: sticky;
  bottom: 16px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
